<template>
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    :title="title"
    top="10vh"
    :visible.sync="dialogFormVisible"
    width="700px"
    @close="close"
  >
    <div v-if="dialogFormVisible" class="abow_dialog">
      <el-form
        ref="form"
        class="activitiesForm"
        label-position="right"
        label-width="120px"
        :model="form"
        :rules="rules"
      >
        <el-form-item label="名称:" prop="item_name">
          <div v-if="pageState != 'view'">
            <el-input
              v-model.trim="form.item_name"
              class="width200"
              clearable
              maxlength="50"
              placeholder="请输入名称"
              show-word-limit
              @input="forceUpdata($event)"
            />
            <el-switch
              v-model="form.is_show_name"
              active-text="显示"
              :active-value="1"
              inactive-text="隐藏"
              :inactive-value="0"
            />
          </div>
          <span v-else>{{ form.item_name }}</span>
        </el-form-item>

        <el-form-item label="高度:" prop="height">
          <div v-if="pageState != 'view'">
            <el-input
              v-model.trim="form.height"
              class="width200"
              clearable
              placeholder="请输入高度"
              show-word-limit
              @input="forceUpdata($event)"
            />
            <el-switch
              v-model="form.is_show_height"
              active-text="显示"
              :active-value="1"
              inactive-text="隐藏"
              :inactive-value="0"
            />
          </div>
          <span v-else
            >{{ formatDecimal(form.height_min) }} ~
            {{ formatDecimal(form.height_max) }} cm
          </span>
        </el-form-item>

        <el-form-item label="胸地胫:" prop="xdj">
          <div v-if="pageState != 'view'">
            <el-input
              v-model.trim="form.xdj"
              class="width200"
              clearable
              placeholder="请输入胸地胫"
              show-word-limit
              @input="forceUpdata($event)"
            />
            <el-switch
              v-model="form.is_show_xdj"
              active-text="显示"
              :active-value="1"
              inactive-text="隐藏"
              :inactive-value="0"
            />
          </div>
          <span v-else
            >{{ formatDecimal(form.xdj_min) }} ~
            {{ formatDecimal(form.xdj_max) }} cm</span
          >
        </el-form-item>

        <el-form-item label="冠幅:" prop="gf">
          <div v-if="pageState != 'view'">
            <el-input
              v-model.trim="form.gf"
              class="width200"
              clearable
              placeholder="请输入冠幅"
              show-word-limit
              @input="forceUpdata($event)"
            />
            <el-switch
              v-model="form.is_show_gf"
              active-text="显示"
              :active-value="1"
              inactive-text="隐藏"
              :inactive-value="0"
            />
          </div>
          <span v-else
            >{{ formatDecimal(form.gf_min) }} ~ {{ formatDecimal(form.gf_max) }} cm</span
          >
        </el-form-item>

        <el-form-item label="分支点数:" prop="fzd_num">
          <div v-if="pageState != 'view'">
            <el-input
              v-model.trim="form.fzd_num"
              class="width200"
              clearable
              placeholder="请输入分支点数"
              show-word-limit
              @input="forceUpdata($event)"
            />
            <el-switch
              v-model="form.is_show_fzd_num"
              active-text="显示"
              :active-value="1"
              inactive-text="隐藏"
              :inactive-value="0"
            />
          </div>
          <span v-else>{{ formatDecimal(form.fzd_num) }}</span>
        </el-form-item>

        <el-form-item label="分支点高度:" prop="fzd_height">
          <div v-if="pageState != 'view'">
            <el-input
              v-model.trim="form.fzd_height"
              class="width200"
              clearable
              placeholder="请输入分支点高度"
              show-word-limit
              @input="forceUpdata($event)"
            />
            <el-switch
              v-model="form.is_show_fzd_height"
              active-text="显示"
              :active-value="1"
              inactive-text="隐藏"
              :inactive-value="0"
            />
          </div>
          <span v-else>
            {{ formatDecimal(form.fzd_height_min) }} ~
            {{ formatDecimal(form.fzd_height_max) }} cm</span
          >
        </el-form-item>

        <el-form-item label="价格:" prop="price">
          <div v-if="pageState != 'view'">
            <el-input
              v-model.trim="form.price"
              class="width200"
              clearable
              placeholder="请输入价格"
              show-word-limit
              @input="forceUpdata($event)"
            />
            <el-switch
              v-model="form.is_show_price"
              active-text="显示"
              :active-value="1"
              inactive-text="隐藏"
              :inactive-value="0"
            />
          </div>
          <span v-else>{{ form.price }}</span>
        </el-form-item>

        <el-form-item label="苗场:" prop="site">
          <div v-if="pageState != 'view'">
            <el-input
              v-model.trim="form.site"
              class="width200"
              clearable
              placeholder="请输入苗场"
              show-word-limit
              @input="forceUpdata($event)"
            />
            <el-switch
              v-model="form.is_show_site"
              active-text="显示"
              :active-value="1"
              inactive-text="隐藏"
              :inactive-value="0"
            />
          </div>
          <span v-else>{{ form.site }}</span>
        </el-form-item>

        <el-form-item label="位置:" prop="location">
          <div v-if="pageState != 'view'">
            <el-input
              v-model.trim="form.location"
              class="width200"
              clearable
              placeholder="请输入位置"
              show-word-limit
              @input="forceUpdata($event)"
            />
            <el-switch
              v-model="form.is_show_location"
              active-text="显示"
              :active-value="1"
              inactive-text="隐藏"
              :inactive-value="0"
            />
          </div>
          <span v-else>{{ form.location }}</span>
        </el-form-item>

        <el-form-item label="日期:" prop="date_time">
          <div v-if="pageState != 'view'">
            <el-date-picker
              v-model="form.date_time"
              class="width200"
              :picker-options="pickerOptionsStart"
              placeholder="请选择日期"
              type="date"
              :value-format="`yyyy-MM-dd`"
            />
            <el-switch
              v-model="form.is_show_date_time"
              active-text="显示"
              :active-value="1"
              inactive-text="隐藏"
              :inactive-value="0"
            />
          </div>
          <span v-else>{{ form.date_time }}</span>
        </el-form-item>

        <el-form-item label="备注:" prop="remark">
          <div v-if="pageState != 'view'">
            <el-input
              v-model.trim="form.remark"
              class="width200"
              clearable
              placeholder="请输入备注"
              show-word-limit
              @input="forceUpdata($event)"
            />
            <el-switch
              v-model="form.is_show_remark"
              active-text="显示"
              :active-value="1"
              inactive-text="隐藏"
              :inactive-value="0"
            />
          </div>
          <span v-else>{{ form.remark }}</span>
        </el-form-item>

        <el-form-item label="图片:" prop="home_image">
          <nxp-upload
            v-bind="$attrs"
            ref="nxpUpload"
            :accessories="activeMainImg.accessories"
            :page-state="pageState"
            :upload-item="activeMainImg"
            @getUploadList="getUploadList"
          />
        </el-form-item>
      </el-form>
    </div>

    <template #footer>
      <el-button v-if="pageState != 'view'" type="primary" @click="save">
        确 定
      </el-button>
      <el-button @click="close">
        {{ pageState == "add" ? "取 消" : "关 闭" }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  saveDataItem,
  varietySaveItem,
  queryItemDataDetail,
  queryDataDetail,
  delDataItem,
} from "@/project/eztree/baseApi/TreeItemApi";
import { httpBaseUrl } from "@/config";
import { getCurrentTime } from "@/project/eztree/baseTool/time";
// import { parseTime, addWaterMarker } from './water';
export default {
  name: "AddEdit",
  components: {},
  props: {
    selectOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    userItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      longit: "", // 经度
      latit: "", // 纬度
      dialogTableVisible: false,
      currentImageUrl: "",
      pageObj: {
        pageType: "components",
        selectType: "checkBox",
      },
      formData: {},
      user_id: 0,
      project_id: 0,
      variety_id: 0,
      form: {
        id: "",
        item_name: "",
        height: "",
        home_image: "",
        xdj: "",
        gf: "",
        fzd_num: "",
        fzd_height: "",
        site: "",
        price: "",
        date_time: null,
        remark: "",
        watermark_pos: 3,
        is_show_date_time: 1,
        is_show_fzd_height: 1,
        is_show_fzd_num: 1,
        is_show_gf: 1,
        is_show_height: 1,
        is_show_location: 1,
        is_show_name: 1,
        is_show_price: 1,
        is_show_remark: 0,
        is_show_site: 1,
        is_show_xdj: 1,
      },
      checkValid: false,
      //   设置开始时间应不允许大于结束时间
      pickerOptionsStart: {
        disabledDate: (time) => {
          return time.getTime(new Date()) > Date.now();
        },
      },
      rules: {
        item_name: [{ required: true, trigger: "blur", message: "请输入名称" }],
        home_image: [
          {
            validator: function (rule, value, callback) {
              return callback();
            },
            required: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      pageState: "add",
      title: "",
      dialogFormVisible: false,
      activeMainImg: {
        type: "uploadFile",
        label: "图片",
        model: [],
        prop: "home_image",
        rules: {
          required: true,
          files: true,
        },
        accessories: {
          dataType: "string",
          disabled: false,
          multiple: false,
          limit: 5,
          accept: ".jpg,.png",
          listType: "picture-card",
          fileParam: {},
        },
      },
    };
  },
  watch: {},
  mounted() {},
  methods: {
    getUploadList(uploadItem, result) {
      if (uploadItem.accessories.accept == ".xlsx") {
        this.form[uploadItem.prop] = result[0].data;
      }
      if (uploadItem.accessories.accept == ".jpg,.png") {
        this.form[uploadItem.prop] = result.map((ele) => {
          return ele.url;
        });
      }
      console.log("getUploadList", uploadItem, result, this.form);
    },
    forceUpdata(e) {
      this.$forceUpdate();
    },
    // 打开弹框
    showEdit(row, action, item_ids) {
      console.log("打开弹框", row);
      let that = this;
      this.pageState = action;
      this.title =
        action == "add"
          ? "创建苗木"
          : action == "edit"
          ? "编辑苗木"
          : action == "view"
          ? "查看苗木"
          : "";
      if (action == "delete") {
        this.$confirm("确认删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            delDataItem({ item_ids }).then(({ code, data }) => {
              if (code == "200") {
                this.$baseMessage("删除成功", "success", "vab-hey-message-success");
                this.$emit("update-fetch", this.form.user_id);
              }
            });
          })
          .catch(() => {});
        return false;
      }
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        if (action == "add") {
          // 新增
          queryDataDetail({}).then(({ code, data }) => {
            this.form = Object.assign({}, data);
            this.form.date_time = getCurrentTime();
            that.form.location = "上海";
            that.form.lat = "31.26451";
            that.form.lon = "121.50515";
            const { variety_id = 0, project_info, user_info } = row;
            this.user_id = this.userItem.id || user_info.id;
            this.project_id = project_info && project_info.id;
            this.variety_id = variety_id && variety_id;
            console.log("新增", that.form, this.variety_id);
          });
          return false;
        }
        // 修改
        let params = Object.assign({});
        params.item_id = row.id;
        queryItemDataDetail(params).then(({ code, data }) => {
          this.form = Object.assign({}, data);
          this.form.home_image = data.cover_image;
          this.$refs.nxpUpload.callBackItemImg(data.images);
          // let listImg = []
          // data.images.map(async (item) => {
          //   const a = await addWaterMarker(item, "#markImg")
          //   listImg.push(a)
          // })
          // console.log('listImg', listImg);

          const field = ["height", "xdj", "gf", "fzd_height"];
          field.map((x) => {
            if (this.form[x + "_min"] && this.form[x + "_max"]) {
              this.form[x] =
                this.form[x + "_min"] === this.form[x + "_max"]
                  ? this.form[x + "_min"]
                  : this.form[x + "_min"] + "~" + this.form[x + "_max"];
            }
          });
          console.log("修改", this.form);
        });
      });
    },
    transToField(mode) {
      const nums = [
        // 'height',
        "height_min",
        "height_max",
        // 'xdj',
        "xdj_min",
        "xdj_max",
        // 'gf',
        "gf_min",
        "gf_max",
        "fzd_num",
        // 'fzd_height',
        "fzd_height_min",
        "fzd_height_max",
        "price",
        "created_num",
      ];
      this.formData = Object.assign({}, this.form);
      for (let k in this.formData) {
        if (k.includes("is_")) {
          this.formData[k] = parseInt(this.formData[k]);
        }
        if (nums.includes(k)) {
          this.formData[k] = this.formatDecimal(this.formData[k]);
        }
      }

      const field = ["height", "xdj", "gf", "fzd_height"];
      field.map((x) => {
        if (mode === "join") {
          if (this.formData[x + "_min"] && this.formData[x + "_max"]) {
            this.formData[x] =
              this.formData[x + "_min"] === this.formData[x + "_max"]
                ? this.formData[x + "_min"]
                : this.formData[x + "_min"] + "~" + this.formData[x + "_max"];
          }
        } else if (mode === "split") {
          let minmax = this.formData[x];
          if (minmax && minmax.length) {
            minmax = minmax.split(/[^\d.]/g);
            minmax = minmax.length === 2 ? minmax : [minmax[0], minmax[0]];
            minmax.sort((a, b) => {
              return parseFloat(a) - parseFloat(b);
            });

            this.formData[x + "_min"] = minmax[0];
            this.formData[x + "_max"] = minmax[1];
          }
        }
      });
      if (mode === "join") {
        this.$refs.uForm.resetFields();
      }
    },
    formatDecimal(old) {
      old = parseFloat(old);
      old = (old || "").toString();
      //拷贝一份 返回去掉零的新串
      let newstr = old;
      //循环变量 小数部分长度
      let leng = old.length - old.indexOf(".") - 1;
      //判断是否有效数
      if (old.indexOf(".") > -1) {
        //循环小数部分
        for (let i = leng; i > 0; i--) {
          //如果newstr末尾有0
          if (newstr.lastIndexOf("0") > -1 && newstr.substr(newstr.length - 1, 1) == 0) {
            let k = newstr.lastIndexOf("0");
            //如果小数点后只有一个0 去掉小数点
            if (newstr.charAt(k - 1) == ".") {
              return newstr.substring(0, k - 1);
            } else {
              //否则 去掉一个0
              newstr = newstr.substring(0, k);
            }
          } else {
            //如果末尾没有0
            return newstr;
          }
        }
      }
      return old;
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    save() {
      this.transToField("split");
      this.$refs.nxpUpload.validateSubmit();
      this.$refs["form"].validate((valid) => {
        Promise.all([this.$refs.nxpUpload.checkValid, valid])
          .then((values) => {
            //都调成功以后执行的操作
            if (values.every((item) => !!item)) {
              this.formData.images = this.form.home_image;
              this.formData.user_id =
                this.userItem.id || this.form.user_id || this.user_id;
              this.formData.watermark_pos = 3;
              this.formData.project_id = this.project_id;
              this.formData.variety_id = this.variety_id;
              delete this.formData.home_image;
              delete this.formData.id;
              console.log("params", this.variety_id, this.formData);
              saveDataItem(this.formData).then(({ code, data }) => {
                if (code == "200") {
                  this.$baseMessage("操作成功", "success", "vab-hey-message-success");
                  this.$emit("update-fetch", this.formData.user_id);
                  this.close();
                }
              });
            } else {
              this.$baseMessage("必填项未填写", "error", "vab-hey-message-error");
            }
          })
          .catch((err) => {
            // 抛出错误信息
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.width200 {
  width: 400px;
  margin-right: 10px;
}

#markImg {
  width: 300px;
  height: 300px;
  position: relative;
}
</style>
