<template>
  <div class="user-management-container">
    <nxp-search
      :config-list="searchConfigList"
      @clickSearch="clickSearch"
      @resetForm="resetForm"
    />
    <vab-query-form> </vab-query-form>
    <!-- 表格 -->
    <select-table
      :accessories="nxpTabelAccessories"
      only-key-id="id"
      :show-btn="true"
      :table-columns="tableColumns"
      :table-data="tableData"
      :table-loading="tableLoading"
      :w-theight="320"
      @delSelect="delSelect"
      @fetchData="fetchData"
      @fetchTableSelection="fetchTableSelection"
    />

    <addEdit ref="edit" :select-options="selectOptions" @update-fetch="initQuery" />
    <!-- 预览 -->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      size="tiny"
      title="查看大图"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <img alt="" class="imgPreviewDialog" :src="currentImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
import {
  queryDataList,
  changeDataTag,
  delDataItem,
} from "@/project/eztree/baseApi/TreeItemApi";
import { querySeedingDataList } from "@/project/eztree/baseApi/commonApi";
const selectOptions = [
  { label: "否", value: "0" },
  { label: "是", value: "1" },
];
export default {
  name: "TreeItem",
  components: {
    addEdit: () => import("./addEdit"),
  },
  props: {
    userPhone: {
      type: String,
    },
  },
  data() {
    return {
      selectOptions: selectOptions,
      queryForm: {},
      dialogVisible: false,
      currentImageUrl: "",
      select_table_data: [],
      searchConfigList: [
        {
          type: "text",
          showName: false,
          model: "",
          param: "phone",
          label: "手机号",
        },
        {
          type: "text",
          showName: false,
          model: "",
          param: "item_name",
          label: "苗木名称",
        },

        {
          type: "daterange",
          showName: false,
          model: "",
          param: "date",
          label: "日期",
         
        },
      ],
      tableLoading: false,
      tableData: [],
      nxpTabelAccessories: {
        pageType: "components",
        selectType: "checkBox",
        pageNo: 1,
        pageSize: 15,
        total: 0,
        height: this.$baseTableHeight(1),
      },
    };
  },
  computed: {
    tableColumns() {
      let columns = [
        {
          label: "苗店头像",
          prop: "shop_avatar",
          width: "160px",
          align: "center",
          render: function (h, params) {
            return h("div", [
              h("img", {
                attrs: {
                  style: "height:100px;",
                  src: params.row.shop_avatar,
                  alt: "图片走丢了",
                },
              }),
            ]);
          },
        },
        {
          label: "苗店手机号",
          prop: "shop_phone",
          width: "160px",
        },
        {
          label: "苗店名称",
          prop: "shop_name",
          width: "160px",
        },

        {
          label: "封面图",
          prop: "cover_image",
          width: "160px",
          align: "center",
          render: function (h, params) {
            return h("div", [
              h("img", {
                attrs: {
                  style: "height:100px;",
                  src: params.row.cover_image,
                  alt: "图片走丢了",
                },
              }),
            ]);
          },
        },
        {
          label: "苗木信息",
          prop: "info",
          align: "center",
          width: 500,
          render: function (h, params) {
            return h("div", {
              domProps: {
                innerHTML: `<div style="text-align: left; padding: 10px;">
                      <div style="line-height: 22px;">
                          <span style="float:left;width: 50%;">苗木名称：${params.row.name}</span>
                          <span style="float:left;width: 50%;">日期：${params.row.date_time}</span>
                      </div>
                      <div style="line-height: 22px;">
                          <span style="float:left;width: 50%;">高度：${params.row.height_min}-${params.row.height_max}cm</span>
                          <span style="float:left;width: 50%;">胸地径：${params.row.xdj_min}-${params.row.xdj_max}cm</span>
                      </div>
                      <div style="line-height: 22px;">
                          <span style="float:left;width: 50%;">冠幅：${params.row.gf_min}-${params.row.gf_max}cm</span>
                          <span style="float:left;width: 50%;">分支点数：${params.row.fzd_num}</span>
                      </div>
                      <div style="line-height: 22px;">
                          <span style="float:left;width: 50%;">分支点高度：${params.row.fzd_height_min}-${params.row.fzd_height_max}cm</span>
                          <span style="float:left;width: 50%;">价格：${params.row.price}元</span>
                      </div>
                      <div style="line-height: 22px;">
                          <span style="float:left;width: 50%;">苗场：${params.row.site}</span>
                          <span style="float:left;width: 50%;">位置：${params.row.location}</span>
                      </div>
                      <div style="line-height: 22px;">
                          <span>备注：${params.row.remark}</span>
                      </div>
                    </div>`,
              },
            });
          },
        },
        {
          label: "创建时间",
          width: "160px",
          prop: "created_at",
        },
        {
          label: "修改时间",
          width: "160px",
          prop: "updated_at",
        },
        // {
        //   label: "是否推荐",
        //   prop: "is_recommend",
        //   width: 100,
        //   render: (h, params) => {
        //     return h(
        //       "div",
        //       {
        //         style: {},
        //       },
        //       [
        //         h(
        //           "el-tag",
        //           {
        //             attrs: {
        //               style: "margin-bottom:10px;display:block",
        //               type:
        //                 params.row.is_recommend == "0"
        //                   ? "info"
        //                   : params.row.is_recommend == "1"
        //                   ? "success"
        //                   : "",
        //               effect: "plain",
        //             },
        //           },
        //           params.row.is_recommend == "0"
        //             ? "未推荐"
        //             : params.row.is_recommend == "1"
        //             ? "已推荐"
        //             : ""
        //         ),

        //         h(
        //           "el-button",
        //           {
        //             attrs: {
        //               type: params.row.is_recommend == "0" ? "primary" : "warning",
        //               params: params.row,
        //             },
        //             on: {
        //               ...this.$listeners,
        //               click: () => {
        //                 this.changeStatus(params.row, "recommend");
        //               },
        //             },
        //           },
        //           params.row.is_recommend == "0"
        //             ? "加入推荐"
        //             : params.row.is_recommend == "1"
        //             ? "取消推荐"
        //             : ""
        //         ),
        //       ]
        //     );
        //   },
        // },
        // {
        //   label: "是否附近",
        //   prop: "is_nearby",
        //   width: 100,
        //   render: (h, params) => {
        //     return h("div", {}, [
        //       h(
        //         "el-tag",
        //         {
        //           attrs: {
        //             style: "margin-bottom:10px;display:block",
        //             type:
        //               params.row.is_nearby == "0"
        //                 ? "info"
        //                 : params.row.is_nearby == "1"
        //                 ? "success"
        //                 : "",
        //             effect: "plain",
        //           },
        //         },
        //         params.row.is_nearby == "0"
        //           ? "未在附近"
        //           : params.row.is_nearby == "1"
        //           ? "已在附近"
        //           : ""
        //       ),

        //       h(
        //         "el-button",
        //         {
        //           attrs: {
        //             type: params.row.is_nearby == "0" ? "primary" : "warning",
        //             size: "small",
        //             params: params.row,
        //           },
        //           on: {
        //             ...this.$listeners,
        //             click: () => {
        //               this.changeStatus(params.row, "nearby");
        //             },
        //           },
        //         },
        //         params.row.is_nearby == "0"
        //           ? "加入附近"
        //           : params.row.is_nearby == "1"
        //           ? "取消附近"
        //           : ""
        //       ),
        //     ]);
        //   },
        // },
        {
          label: "操作",
          width: 200,
          render: (h, params) => {
            return h("div", {}, [
              h(
                "el-button",
                {
                  attrs: {
                    type: "text",
                    params: params.row,
                  },
                  on: {
                    ...this.$listeners,
                    click: () => {
                      this.dialogVisible = true;
                      this.currentImageUrl = params.row.cover_image;
                    },
                  },
                },
                "查看大图"
              ),
              // h(
              //   "el-button",
              //   {
              //     attrs: {
              //       type: "text",
              //       params: params.row,
              //     },
              //     on: {
              //       ...this.$listeners,
              //       click: () => {
              //         this.$refs["edit"].showEdit(params.row, "edit");
              //       },
              //     },
              //   },
              //   "编辑"
              // ),

              // h(
              //   "el-button",
              //   {
              //     attrs: {
              //       type: "text",
              //       params: params.row,
              //     },
              //     on: {
              //       ...this.$listeners,
              //       click: () => {
              //         this.$refs["edit"].showEdit(params.row, "view");
              //       },
              //     },
              //   },
              //   "详情"
              // ),

              // h(
              //   "el-button",
              //   {
              //     attrs: {
              //       type: "text",
              //       params: params.row,
              //     },
              //     on: {
              //       ...this.$listeners,
              //       click: () => {
              //         this.handleEdit(params.row, "delete");
              //       },
              //     },
              //   },
              //   "删除"
              // ),
            ]);
          },
        },
      ];
      return columns;
    },
  },
  watch: {},
  mounted() {},
  created() {},
  methods: {
    changeStatus(row, flag) {
      let txt = "";
      let action = "";
      if (flag == "recommend") {
        txt =
          row.is_recommend == "0"
            ? "你确认加入推荐吗？"
            : row.is_recommend == "1"
            ? "你确认取消推荐吗？"
            : "";
        action = row.is_recommend == "0" ? "add" : row.is_recommend == "1" ? "del" : "";
      }
      if (flag == "nearby") {
        txt =
          row.is_nearby == "0"
            ? "你确认加入附近吗？"
            : row.is_nearby == "1"
            ? "你确认取消附近吗？"
            : "";
        action = row.is_nearby == "0" ? "add" : row.is_nearby == "1" ? "del" : "";
      }
      this.$confirm(txt, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = Object.assign(
            {},
            {
              id: row.id,
              type: flag,
              action,
            }
          );
          changeDataTag(params).then(({ code, data }) => {
            if (code == "200") {
              this.$baseMessage("操作成功", "success", "vab-hey-message-success");
              this.initQuery();
            }
          });
        })
        .catch(() => {});
    },
    // 初始化
    async initQuery() {
      this.tableLoading = true;
      let params = Object.assign({}, this.queryForm);
      params.page = this.nxpTabelAccessories.pageNo;
      params.page_size = this.nxpTabelAccessories.pageSize;
      if (this.userPhone) {
        this.searchConfigList[0].model = this.userPhone;
        params.phone = this.userPhone;
      }
      const { date } = params;
      if (date) {
        params.start_time = date[0];
        params.end_time = date[1];
        console.log("params", params, date);
        delete params.date;
      }
      querySeedingDataList(params).then(({ code, data }) => {
        if (code == "200") {
          this.tableData = (data && data.list) || [];
          this.nxpTabelAccessories.total = data.total_count;
        }
        this.tableLoading = false;
      });
    },
    // 分页查询
    fetchData(val) {
      this.nxpTabelAccessories.pageNo = val.pageNo;
      this.nxpTabelAccessories.pageSize = val.pageSize;
      this.initQuery();
    },
    // 模糊查询
    clickSearch(val) {
      this.queryForm = val;
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
      this.select_table_data = [];
    },
    resetForm() {
      this.queryForm = {};
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    fetchTableSelection(data) {
      this.select_table_data = data;
    },
    handleEdit(row, action) {
      let item_ids = [row.id];
      this.$refs["edit"].showEdit(row, action, item_ids);
    },
    delSelect(row, action) {
      let item_ids = this.select_table_data.map((ele) => {
        return ele.id;
      });
      delDataItem({ item_ids })
        .then(({ code, data }) => {
          if (code == "200") {
            this.$baseMessage("删除成功", "success", "vab-hey-message-success");
            this.initQuery();
          }
        })
        .catch(() => {});
    },
  },
};
</script>
