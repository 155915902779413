import { render, staticRenderFns } from "./addEdit.vue?vue&type=template&id=1ab56380&scoped=true"
import script from "./addEdit.vue?vue&type=script&lang=js"
export * from "./addEdit.vue?vue&type=script&lang=js"
import style0 from "./addEdit.vue?vue&type=style&index=0&id=1ab56380&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ab56380",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("F:\\zlwork\\eztree-admin\\node_modules\\_vue-hot-reload-api@2.3.4@vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1ab56380')) {
      api.createRecord('1ab56380', component.options)
    } else {
      api.reload('1ab56380', component.options)
    }
    module.hot.accept("./addEdit.vue?vue&type=template&id=1ab56380&scoped=true", function () {
      api.rerender('1ab56380', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/project/eztree/page/TreeItem/addEdit.vue"
export default component.exports