var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        "close-on-click-modal": false,
        title: _vm.title,
        top: "10vh",
        visible: _vm.dialogFormVisible,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _vm.pageState != "view"
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v(" 确 定 ")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.close } }, [
                _vm._v(
                  " " + _vm._s(_vm.pageState == "add" ? "取 消" : "关 闭") + " "
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.dialogFormVisible
        ? _c(
            "div",
            { staticClass: "abow_dialog" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "activitiesForm",
                  attrs: {
                    "label-position": "right",
                    "label-width": "120px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称:", prop: "item_name" } },
                    [
                      _vm.pageState != "view"
                        ? _c(
                            "div",
                            [
                              _c("el-input", {
                                staticClass: "width200",
                                attrs: {
                                  clearable: "",
                                  maxlength: "50",
                                  placeholder: "请输入名称",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.forceUpdata($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.item_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "item_name",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.item_name",
                                },
                              }),
                              _c("el-switch", {
                                attrs: {
                                  "active-text": "显示",
                                  "active-value": 1,
                                  "inactive-text": "隐藏",
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.is_show_name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_show_name", $$v)
                                  },
                                  expression: "form.is_show_name",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(_vm.form.item_name))]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "高度:", prop: "height" } },
                    [
                      _vm.pageState != "view"
                        ? _c(
                            "div",
                            [
                              _c("el-input", {
                                staticClass: "width200",
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入高度",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.forceUpdata($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.height,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "height",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.height",
                                },
                              }),
                              _c("el-switch", {
                                attrs: {
                                  "active-text": "显示",
                                  "active-value": 1,
                                  "inactive-text": "隐藏",
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.is_show_height,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_show_height", $$v)
                                  },
                                  expression: "form.is_show_height",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              _vm._s(_vm.formatDecimal(_vm.form.height_min)) +
                                " ~ " +
                                _vm._s(_vm.formatDecimal(_vm.form.height_max)) +
                                " cm "
                            ),
                          ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "胸地胫:", prop: "xdj" } },
                    [
                      _vm.pageState != "view"
                        ? _c(
                            "div",
                            [
                              _c("el-input", {
                                staticClass: "width200",
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入胸地胫",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.forceUpdata($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.xdj,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "xdj",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.xdj",
                                },
                              }),
                              _c("el-switch", {
                                attrs: {
                                  "active-text": "显示",
                                  "active-value": 1,
                                  "inactive-text": "隐藏",
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.is_show_xdj,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_show_xdj", $$v)
                                  },
                                  expression: "form.is_show_xdj",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              _vm._s(_vm.formatDecimal(_vm.form.xdj_min)) +
                                " ~ " +
                                _vm._s(_vm.formatDecimal(_vm.form.xdj_max)) +
                                " cm"
                            ),
                          ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "冠幅:", prop: "gf" } },
                    [
                      _vm.pageState != "view"
                        ? _c(
                            "div",
                            [
                              _c("el-input", {
                                staticClass: "width200",
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入冠幅",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.forceUpdata($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.gf,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "gf",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.gf",
                                },
                              }),
                              _c("el-switch", {
                                attrs: {
                                  "active-text": "显示",
                                  "active-value": 1,
                                  "inactive-text": "隐藏",
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.is_show_gf,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_show_gf", $$v)
                                  },
                                  expression: "form.is_show_gf",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              _vm._s(_vm.formatDecimal(_vm.form.gf_min)) +
                                " ~ " +
                                _vm._s(_vm.formatDecimal(_vm.form.gf_max)) +
                                " cm"
                            ),
                          ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分支点数:", prop: "fzd_num" } },
                    [
                      _vm.pageState != "view"
                        ? _c(
                            "div",
                            [
                              _c("el-input", {
                                staticClass: "width200",
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入分支点数",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.forceUpdata($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.fzd_num,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "fzd_num",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.fzd_num",
                                },
                              }),
                              _c("el-switch", {
                                attrs: {
                                  "active-text": "显示",
                                  "active-value": 1,
                                  "inactive-text": "隐藏",
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.is_show_fzd_num,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_show_fzd_num", $$v)
                                  },
                                  expression: "form.is_show_fzd_num",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(_vm._s(_vm.formatDecimal(_vm.form.fzd_num))),
                          ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分支点高度:", prop: "fzd_height" } },
                    [
                      _vm.pageState != "view"
                        ? _c(
                            "div",
                            [
                              _c("el-input", {
                                staticClass: "width200",
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入分支点高度",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.forceUpdata($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.fzd_height,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "fzd_height",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.fzd_height",
                                },
                              }),
                              _c("el-switch", {
                                attrs: {
                                  "active-text": "显示",
                                  "active-value": 1,
                                  "inactive-text": "隐藏",
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.is_show_fzd_height,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "is_show_fzd_height",
                                      $$v
                                    )
                                  },
                                  expression: "form.is_show_fzd_height",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatDecimal(_vm.form.fzd_height_min)
                                ) +
                                " ~ " +
                                _vm._s(
                                  _vm.formatDecimal(_vm.form.fzd_height_max)
                                ) +
                                " cm"
                            ),
                          ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "价格:", prop: "price" } },
                    [
                      _vm.pageState != "view"
                        ? _c(
                            "div",
                            [
                              _c("el-input", {
                                staticClass: "width200",
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入价格",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.forceUpdata($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.price,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "price",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.price",
                                },
                              }),
                              _c("el-switch", {
                                attrs: {
                                  "active-text": "显示",
                                  "active-value": 1,
                                  "inactive-text": "隐藏",
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.is_show_price,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_show_price", $$v)
                                  },
                                  expression: "form.is_show_price",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(_vm.form.price))]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "苗场:", prop: "site" } },
                    [
                      _vm.pageState != "view"
                        ? _c(
                            "div",
                            [
                              _c("el-input", {
                                staticClass: "width200",
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入苗场",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.forceUpdata($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.site,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "site",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.site",
                                },
                              }),
                              _c("el-switch", {
                                attrs: {
                                  "active-text": "显示",
                                  "active-value": 1,
                                  "inactive-text": "隐藏",
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.is_show_site,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_show_site", $$v)
                                  },
                                  expression: "form.is_show_site",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(_vm.form.site))]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "位置:", prop: "location" } },
                    [
                      _vm.pageState != "view"
                        ? _c(
                            "div",
                            [
                              _c("el-input", {
                                staticClass: "width200",
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入位置",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.forceUpdata($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.location,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "location",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.location",
                                },
                              }),
                              _c("el-switch", {
                                attrs: {
                                  "active-text": "显示",
                                  "active-value": 1,
                                  "inactive-text": "隐藏",
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.is_show_location,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_show_location", $$v)
                                  },
                                  expression: "form.is_show_location",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(_vm.form.location))]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "日期:", prop: "date_time" } },
                    [
                      _vm.pageState != "view"
                        ? _c(
                            "div",
                            [
                              _c("el-date-picker", {
                                staticClass: "width200",
                                attrs: {
                                  "picker-options": _vm.pickerOptionsStart,
                                  placeholder: "请选择日期",
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.form.date_time,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "date_time", $$v)
                                  },
                                  expression: "form.date_time",
                                },
                              }),
                              _c("el-switch", {
                                attrs: {
                                  "active-text": "显示",
                                  "active-value": 1,
                                  "inactive-text": "隐藏",
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.is_show_date_time,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_show_date_time", $$v)
                                  },
                                  expression: "form.is_show_date_time",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(_vm.form.date_time))]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注:", prop: "remark" } },
                    [
                      _vm.pageState != "view"
                        ? _c(
                            "div",
                            [
                              _c("el-input", {
                                staticClass: "width200",
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入备注",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.forceUpdata($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "remark",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.remark",
                                },
                              }),
                              _c("el-switch", {
                                attrs: {
                                  "active-text": "显示",
                                  "active-value": 1,
                                  "inactive-text": "隐藏",
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.is_show_remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_show_remark", $$v)
                                  },
                                  expression: "form.is_show_remark",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(_vm.form.remark))]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片:", prop: "home_image" } },
                    [
                      _c(
                        "nxp-upload",
                        _vm._b(
                          {
                            ref: "nxpUpload",
                            attrs: {
                              accessories: _vm.activeMainImg.accessories,
                              "page-state": _vm.pageState,
                              "upload-item": _vm.activeMainImg,
                            },
                            on: { getUploadList: _vm.getUploadList },
                          },
                          "nxp-upload",
                          _vm.$attrs,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }